var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"personal-container"},[_c('app-header'),_c('div',{staticClass:"bg"}),_c('div',{staticClass:"main"},[_c('div',{staticClass:"top"},[_c('div',{staticClass:"top-row"},[_c('span',[_vm._v("歌单信息")]),_c('span',{staticStyle:{"display":"flex"}},[_c('div',{staticClass:"btn",on:{"click":_vm.upSongs}},[_vm._v("编辑歌单")]),_c('div',{staticClass:"btn",on:{"click":_vm.removeSongs}},[_vm._v("删除歌单")])])]),_c('div',{staticClass:"image"},[_c('img',{attrs:{"src":_vm.detail.cover}})]),_c('div',{staticClass:"info"},[_c('div',[_c('span',[_c('strong',[_vm._v("歌单名： ")]),_vm._v(" "+_vm._s(_vm.detail.name))]),_c('span',[_c('strong',[_vm._v("歌单类别： ")]),_vm._v(" "+_vm._s(_vm.detail.listTypeName || "未知"))]),_c('span',[_c('strong',[_vm._v("发行时间： ")]),_vm._v(" "+_vm._s(_vm.detail.releaseTime))])]),_c('div',[_c('strong',[_vm._v("歌单简介: ")]),(
              typeof _vm.detail.introduce == 'undefined' ||
              _vm.detail.introduce == null ||
              _vm.detail.introduce == ''
            )?_c('div',{staticStyle:{"color":"#a0a2a6","font-size":"14px","line-height":"25px"}},[_vm._v(" 没有简介 ")]):_vm._e(),_c('div',{class:{ shrink: _vm.shrink }},[(_vm.shrink)?_c('span',{staticClass:"introduce"},[_vm._v(_vm._s(_vm.detail.introduce && _vm.detail.introduce.slice(0, 20)))]):_c('span',{staticClass:"introduce",domProps:{"innerHTML":_vm._s(_vm.br(_vm.detail.introduce))}}),(_vm.detail.introduce && _vm.detail.introduce.length > 20)?_c('span',{staticStyle:{"color":"#c20c0c","cursor":"pointer","margin-left":"10px"},on:{"click":function($event){_vm.shrink = !_vm.shrink}}},[_vm._v(_vm._s(_vm.shrink ? "展开" : "收起"))]):_vm._e()])])])]),_c('div',{staticClass:"list-title"},[_vm._v(" 歌曲信息"),_c('span',[_vm._v("（"+_vm._s(_vm.total)+"首）")])]),_c('table-my-single',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],attrs:{"list":_vm.singleList,"songs":true,"out":true,"total":_vm.total,"currentPage":_vm.currentPage,"pageSize":_vm.pageSize},on:{"up":function($event){return _vm.$router.push(
          `/upload?up=single&id=${$event.id}&singleName=${$event.name}`
        )},"remove":_vm.removeSingle,"shiftOutSongs":_vm.shiftOutSongs,"currentPageChange":_vm.pageChang}}),_c('div',{staticClass:"upload"},[_vm._m(0),_c('com-button',{attrs:{"btnStyle":{ width: '370px' }},on:{"click":function($event){return _vm.$router.push(
            `/upload?up=single&songsName=${_vm.detail.name}&songsId=${_vm.detail.id}`
          )}}},[_vm._v("点击上传")])],1)],1),_c('app-footer')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tip"},[_c('span',[_vm._v("上传歌曲")]),_vm._v("（歌曲建议使用mp3、m4a格式，音质>320KBps。） ")])
}]

export { render, staticRenderFns }