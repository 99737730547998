<template>
  <div class="personal-container">
    <app-header></app-header>
    <div class="bg"></div>
    <div class="main">
      <div class="top">
        <div class="top-row">
          <span>歌单信息</span>
          <span style="display: flex">
            <div class="btn" @click="upSongs">编辑歌单</div>
            <div class="btn" @click="removeSongs">删除歌单</div>
          </span>
        </div>
        <div class="image"><img :src="detail.cover" /></div>
        <div class="info">
          <div>
            <span><strong>歌单名： </strong> {{ detail.name }}</span>
            <span
              ><strong>歌单类别： </strong>
              {{ detail.listTypeName || "未知" }}</span
            >
            <span><strong>发行时间： </strong> {{ detail.releaseTime }}</span>
          </div>
          <div>
            <strong>歌单简介: </strong>
            <div
              style="color: #a0a2a6; font-size: 14px; line-height: 25px"
              v-if="
                typeof detail.introduce == 'undefined' ||
                detail.introduce == null ||
                detail.introduce == ''
              "
            >
              没有简介
            </div>
            <div :class="{ shrink: shrink }">
              <span v-if="shrink" class="introduce">{{
                detail.introduce && detail.introduce.slice(0, 20)
              }}</span>
              <span
                v-else
                class="introduce"
                v-html="br(detail.introduce)"
              ></span>
              <span
                @click="shrink = !shrink"
                v-if="detail.introduce && detail.introduce.length > 20"
                style="color: #c20c0c; cursor: pointer; margin-left: 10px"
                >{{ shrink ? "展开" : "收起" }}</span
              >
            </div>
          </div>
        </div>
      </div>
      <!--      <div class="top" v-else></div>-->
      <div class="list-title">
        歌曲信息<span>（{{ total }}首）</span>
      </div>
      <table-my-single
        v-loading="loading"
        :list="singleList"
        :songs="true"
        :out="true"
        @up="
          $router.push(
            `/upload?up=single&id=${$event.id}&singleName=${$event.name}`
          )
        "
        @remove="removeSingle"
        @shiftOutSongs="shiftOutSongs"
        :total="total"
        :currentPage="currentPage"
        :pageSize="pageSize"
        @currentPageChange="pageChang"
      ></table-my-single>
      <div class="upload">
        <div class="tip">
          <span>上传歌曲</span>（歌曲建议使用mp3、m4a格式，音质>320KBps。）
        </div>
        <com-button
          :btnStyle="{ width: '370px' }"
          @click="
            $router.push(
              `/upload?up=single&songsName=${detail.name}&songsId=${detail.id}`
            )
          "
          >点击上传</com-button
        >
      </div>
    </div>

    <app-footer></app-footer>
  </div>
</template>

<script>
import appHeader from "@/components/app-header";
import appFooter from "@/components/app-footer";
import tableMySingle from "@/components/table/table-my-single";
import comButton from "@/components/com/com-button";
import { Single } from "@/class/music";
export default {
  components: {
    appHeader,
    appFooter,
    tableMySingle,
    comButton,
  },
  data() {
    return {
      singleList: [],
      detail: {},
      shrink: true,
      currentPage: 1,
      pageSize: 10,
      total: 0,
      loading: false,
    };
  },
  computed: {
    id() {
      return this.$route.query.id;
    },
    name() {
      return this.$route.query.name;
    },
    userInfo() {
      return this.$store.state.user.userInfo;
    },
  },
  watch: {
    "$store.state.user.userInfo": {
      handler(v) {
        !v && this.$router.push("/");
      },
    },
    // total(){
    //   let {total, pageSize, currentPage } = this;
    //   // 4 5 2 4
    //   console.log(total <= pageSize * (currentPage - 1) )
    //   total <= pageSize * (currentPage - 1) && this.currentPage--;
    // }
  },
  methods: {
    /*
     * 删除之后判断是否为当前页的是否没有数据
     * 没有数据则 currentPage-1
     * */
    setRemoveAlterCurrentPage() {
      let { total, pageSize, currentPage } = this;
      console.log(total, pageSize, currentPage);
      if (currentPage !== 1 && (currentPage - 1) * pageSize === total - 1) {
        this.currentPage--;
      }
    },
    sendGetList() {
      this.loading = true;
      this.$api
        .bySongsGetSingleList({
          size: this.pageSize,
          current: this.currentPage,
          userId: this.userInfo.id,
          listId: this.id,
          shelves: null,
        })
        .then((resolve) => {
          // console.log(resolve.data.records[0].musicName)
          this.loading = false;
          this.total = resolve.data.total;
          this.singleList = resolve.data.records.map((item) => {
            item.name = item.musicName;
            item.id = item.musicId;
            let a = new Single(item);
            return a;
          });
        })
        .catch(() => (this.loading = false));
    },
    sendGetDetail() {
      this.$api.songsDetail({ id: this.id }).then((resolve) => {
        this.detail = resolve.data;
      });
    },
    removeSongs() {
      this.$confirm("确认删除歌单？").then(() => {
        this.loading = true;
        this.$api
          .removeSongs({ ids: this.detail.id })
          .then((resolve) => {
            // console.log(resolve);
            this.loading = false;
            if (resolve.code === 200) {
              // this.sendGetList();
              this.$router.push("/personal");
            }
          })
          .catch(() => (this.loading = false));
      });
    },
    shiftOutSongs(d) {
      this.$confirm("确认移出此歌单？").then(() => {
        this.loading = true;
        this.$api
          .outSongsMusic({ listId: ~~this.id, musicId: d.id })
          .then((resolve) => {
            this.loading = false;
            if (resolve.code === 200) {
              // this.$store.commit("SET_ROUTER_KEY");
              this.setRemoveAlterCurrentPage();
              this.sendGetList();
            }
          })
          .catch(() => (this.loading = false));
      });
    },
    removeSingle(d) {
      this.$confirm("确认删除歌曲？").then(() => {
        this.loading = true;
        this.$api
          .removeSingle({ ids: d.id })
          .then((resolve) => {
            // console.log(resolve);
            this.loading = false;
            if (resolve.code === 200) {
              // this.$store.commit("SET_ROUTER_KEY");
              let playIndex = this.$store.state.playId
              this.$store.commit('DEL_PLAYLIST',{d,playIndex})
              this.setRemoveAlterCurrentPage();
              this.sendGetList();
            }
          })
          .catch(() => (this.loading = false));
      });
    },
    upSongs() {
      this.$router.push(`/upload?up=songs&id=${this.detail.id}&isUpSongs=1`);
    },
    br(v) {
      let res = v ? v.replace(/\n/g, "<br>") : "";
      return res;
    },
    pageChang(currentPage) {
      this.currentPage = currentPage;
      this.sendGetList();
    },
  },
  mounted() {
    console.log(123);
    this.sendGetList();
    this.sendGetDetail();
  },
};
</script>
<style scoped lang="scss">
@import "/src/style/css";
@import "/src/style/personal";

.main {
  margin: auto;
  width: $containerWidth;
}
.top {
  z-index: 10;
  min-height: 317px;
  background: #ffffff;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.11);
  border-radius: 2px;
  padding: 35px 40px 20px;
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  margin-bottom: 70px;
  .top-row {
    height: 73px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    font-size: 28px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #333333;
    //border: 1px solid red;
  }
  .image {
    width: 180px;
    height: 180px;
    overflow: hidden;
    img {
      width: 100%;
    }
  }
  .info {
    //border: 1px solid red;
    margin-left: 46px;
    width: 0;
    flex-grow: 1;

    > div {
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      padding: 5px 0;
      font-size: 16px;
      color: #333;
      margin-top: 30px;
      display: flex;
      align-items: flex-start;
      > span {
        margin-right: 130px;
        max-width: 30%;
        display: inline-block;
      }
      strong {
        flex-shrink: 0;
        margin-right: 10px;
      }
    }
    .shrink {
      display: flex;
      .introduce {
        //border: 1px solid red;
        //display: inline-block;
        //max-width: 500px;
        height: 25px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
  .btn {
    width: 132px;
    height: 42px;
    border-radius: 2px;
    border: 1px solid #d6d6d6;
    line-height: 42px;
    font-size: 16px;
    color: #333333;
    text-align: center;
    margin-left: 20px;
    cursor: pointer;
  }
}
.list-title {
  font-size: 28px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 600;
  color: #333333;
  line-height: 40px;
  letter-spacing: 1px;
  margin-bottom: 30px;
  span {
    color: #a0a2a6;
    font-weight: 400;
  }
}
.upload {
  margin: 37px auto 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  .tip {
    font-size: 13px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #a0a2a6;
    margin-bottom: 11px;
    > span {
      font-size: 16px;
    }
  }
}
</style>
